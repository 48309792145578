import React, { OlHTMLAttributes } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { SpacePalette } from '@UX/themes/types';

export interface ListVariants {
  bullet: SxStyleProp;
  chevron: SxStyleProp;
  diamond: SxStyleProp;
  none: null;
}

const variantStyles: ListVariants = {
  bullet: {
    content: "'\\2022'",
    fontSize: '1.4em' as 'custom',
    lineHeight: '1em',
  },
  chevron: {
    content: "''",
    top: '0.5em',
    padding: '5xs',
    borderWidth: 'activeStrokeWeight',
    borderColor: 'brand',
    borderStyle: 'solid solid none none',
    transform: 'rotate(45deg)',
  },
  diamond: {
    content: "''",
    top: '7px',
    left: '2px',
    borderWidth: 'outlinedStrokeWeight',
    borderStyle: 'solid',
    padding: '3px',
    borderRadius: '2',
    transform: 'rotate(45deg)',
  },
  none: null,
};

export interface VerticalSpacingVariants {
  small: keyof SpacePalette;
  normal: keyof SpacePalette;
  large: keyof SpacePalette;
}

const verticalSpacingValues: VerticalSpacingVariants = {
  small: '4xs',
  normal: '3xs',
  large: 'xs',
};

export type ListType = 'ordered' | 'unordered';

export type OlType = NonNullable<OlHTMLAttributes<any>['type']>;

const listStyleByTypeMap: Record<OlType, string> = {
  '1': 'decimal',
  a: 'lower-alpha',
  A: 'upper-alpha',
  i: 'lower-roman',
  I: 'upper-roman',
};

export interface ListProps extends React.PropsWithChildren<ClassNameProps> {
  variant?: keyof ListVariants;
  verticalSpacing?: keyof VerticalSpacingVariants;
  columns?: number | number[];
  listType?: ListType;
  type?: OlType;
  start?: number;
}

export const List: React.FC<ListProps> = ({
  variant = 'bullet',
  verticalSpacing = 'normal',
  columns,
  className,
  children,
  listType = 'unordered',
  type = '1',
  start = 1,
}) => {
  const isListOrdered = listType === 'ordered';
  const ListTag = isListOrdered ? 'ol' : 'ul';

  return (
    <ListTag
      className={className}
      {...(isListOrdered && { start })}
      sx={{
        ...(isListOrdered && {
          listStyle: listStyleByTypeMap[type] ?? 'decimal',
          paddingLeft: 'xs',
        }),
        columns,
        columnGap: 'xl',
        '> li': {
          position: 'relative',
          marginBottom: verticalSpacingValues[verticalSpacing],
          ...(!isListOrdered && {
            paddingLeft: variant === 'diamond' ? 's' : 'xs',
            '::before': {
              display: 'inline-block',
              position: 'absolute',
              left: 0,
              ...variantStyles[variant],
            },
          }),
        },
      }}
    >
      {children}
    </ListTag>
  );
};
